import React, { useState } from "react";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import SettingsIcon from "@mui/icons-material/Settings";
import { BaseModal } from "../Modal/BaseModal";
import ManageExtension from "../ManageExtension/ManageExtension.jsx";
import "./SpeedDial.css";
const actions = [{ icon: <SettingsIcon />, name: "Manage Extension" }];

export default function BasicSpeedDial() {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = (name) => {
    if (name === "Manage Extension") setOpen((prev) => !prev);
  };

  return (
    <>
      <Box sx={{ height: 320, transform: "translateZ(0px)", flexGrow: 1 }}>
        <SpeedDial
          ariaLabel="SpeedDial basic example"
          // sx={{ position: "absolute", bottom: 16, right: 16 }}
          icon={<SpeedDialIcon />}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={() => handleClick(action.name)}
            />
          ))}
        </SpeedDial>
      </Box>

      <div>
        {open && (
          <BaseModal
            open={open}
            handleClose={handleClose}
            // ManageExtension={ManageExtension}
          >
            {/* <ManageExtension /> */}
            <div style={{ width: 400, height: 180 }}>
              Card to manage extension
            </div>
          </BaseModal>
        )}
      </div>
    </>
  );
}
