import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Modal, Row, Table } from "react-bootstrap";
import InfoIcon from "@mui/icons-material/Info";
import Navbar from "../Navbar";
import Footer from "../Footer";
import UpgradePlanModal from "./UpgradePlanModal";
import { toast } from "react-toastify";
import Loader from "../loader/Loader";
import Cookies from "js-cookie";
import { getPayPalAccessToken } from "../../API/transaction";

import { BASEURL } from "../../constant/constant";

import { getPlanIdByName } from "../../helper/getPlanId";

const UpgradeSubscription = () => {
  const monthlyPrice = process.env.REACT_APP_MONTHLY_PACKAGE;
  const yearlyPrice = process.env.REACT_APP_YEARLY_PACKAGE;
  const groupMonthlyPlanId =
    process.env.REACT_APP_PRODUCT_MONTHLY_GROUP_PLAN_ID;
  const groupYearlyPlanId = process.env.REACT_APP_PRODUCT_YEARLY_GROUP_PLAN_ID;
  const yPrice = yearlyPrice;

  const memCurrQuantity = localStorage.memberQuantity;

  const [show, setShow] = useState(false);
  const [upgradeShow, setUpgradeShow] = useState(false);
  const [monthlyGroupNumber, setMonthlyGroupNumber] = useState(3);
  const [yearlyGroupNumber, setYearlyGroupNumber] = useState(3);
  const [newQuantity, setNewQuantity] = useState();
  const [newYearlyQuantity, setNewYearlyQuantity] = useState();
  const [yearlyShow, setYearlyShow] = useState(false);
  const [priceDetails, setPriceDetails] = useState({});
  const [subscriptionId, setsubscriptionId] = useState("");
  const [currPlanQuantity, setCurrPlanQuantity] = useState(memCurrQuantity);
  const [updatedQty, setupdatedQty] = useState(memCurrQuantity);
  const [updateSubscriptionShow, setUpdateSubscriptionShow] = useState(false);
  const [isLoader, setisLoader] = useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const [newPlanId, setNewPlanId] = useState("");
  const [planType, setPlanType] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [isIndividual, setisIndividual] = useState(false);
  const [newSubType, setNewSubType] = useState("");
  const [plansData, setPlansData] = useState(null);

  const [groupMonthlyRange, setGroupMonthlyRange] = useState("");
  const [groupMonthlyRate, setGroupMonthlyRate] = useState(null);
  const [groupMonthlyCalculatedPrice, setGroupMonthlyCalculatedPrice] =
    useState(null);

  const [groupYearlyRange, setGroupYearlyRange] = useState("");
  const [groupYearlyRate, setGroupYearlyRate] = useState(null);
  const [groupYearlyCalculatedPrice, setGroupYearlyCalculatedPrice] =
    useState(null);

  const [updateCardRange, setUpdateCardRange] = useState(null);
  const [updateCardRate, setUpdateCardRate] = useState(null);
  const [updateCardCalculatedPrice, setUpdateCardCalculatedPrice] =
    useState(null);

  const [quantityForUpdateQunatityCard, setquantityForUpdateQunatityCard] =
    useState(memCurrQuantity);

  const quantityRef = useRef(null);
  const yearlyQuantityRef = useRef(null);

  const MemberTyp = localStorage.getItem("MemberType");

  const [updateVal, setUpdateVal] = useState(false);
  const [payPalAccessToken, setPayPalAccessToken] = useState("");

  const useEmail = JSON.parse(localStorage.getItem("useEmail"));
  const shippingAmount = priceDetails?.proratedData?.totalProratedAmount;

  const showInfoRange = () => {
    setShow(true);
  };

  const showInfoYearlyRange = () => {
    setYearlyShow(true);
  };

  async function getPayPalToken() {
    try {
      const response = await getPayPalAccessToken();

      const token = response?.token;

      // Save the token in cookies
      Cookies.set("payPalToken", token, { expires: 1, secure: true });

      return token;
    } catch (err) {
      console.log("Error : ", err);
    }
  }

  useEffect(() => {
    setisLoader(true);

    const fetchPlansData = async () => {
      try {
        const token = Cookies.get("payPalToken") || (await getPayPalToken());
        console.log("TTTTTTTT: ");

        const response = await fetch(
          `${process.env.REACT_APP_PAYPAL_API_BASEURL}/v1/billing/plans?sort_by=create_time&sort_order=desc&limit=20`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Prefer: "return=representation",
            },
          }
        );

        if (!response.ok) {
          const error = await response.json();
          throw new Error(error.error_description);
        }

        const data = await response.json();

        setPlansData(data?.plans);
        setisLoader(false);
      } catch (error) {
        let token;
        if (
          error.toString().slice(error.toString().indexOf(":") + 1) ===
            " Access Token not found in cache" ||
          error.toString().slice(error.toString().indexOf(":") + 1) ===
            " The token passed in was not found in the system" ||
          error.toString().slice(error.toString().indexOf(":") + 1) ===
            " Token signature verification failed"
        ) {
          token = await getPayPalToken();
          setPayPalAccessToken(token);
        }
        if (!token) {
          console.log("error", error);
          toast.error(
            error.toString().slice(error.toString().indexOf(":") + 1)
          );
        }
      }
    };

    fetchPlansData();
  }, [payPalAccessToken]);

  useEffect(() => {
    const calcualtedQuantity = getMonthlyGroupPrice(monthlyGroupNumber);
    setNewQuantity(calcualtedQuantity);
  }, [monthlyGroupNumber]);

  useEffect(() => {
    const calcualtedQuantity = getYearlyGroupPrice(yearlyGroupNumber);
    setNewYearlyQuantity(calcualtedQuantity);
  }, [yearlyGroupNumber]);

  useEffect(() => {
    const subscriptionId = JSON.parse(localStorage.getItem("subscriptionID"));
    setsubscriptionId(subscriptionId);
  }, []);

  useEffect(() => {
    let cardData;
    cardData = findActivePlanAndCalculateTotal(groupMonthlyPlanId, 3);

    setGroupMonthlyRange(cardData?.groupRange);
    setGroupMonthlyRate(cardData?.pricePerUnit);
    setGroupMonthlyCalculatedPrice(cardData?.totalAmount);

    cardData = findActivePlanAndCalculateTotal(groupYearlyPlanId, 3);

    setGroupYearlyRange(cardData?.groupRange);
    setGroupYearlyRate(cardData?.pricePerUnit);
    setGroupYearlyCalculatedPrice(cardData?.totalAmount);

    if (MemberTyp === "Group monthly") {
      cardData = findActivePlanAndCalculateTotal(
        groupMonthlyPlanId,
        memCurrQuantity
      );
      setUpdateCardRange(cardData?.groupRange);
      setUpdateCardRate(cardData?.pricePerUnit);
      setUpdateCardCalculatedPrice(cardData?.totalAmount);
    } else if (MemberTyp === "Group Yearly") {
      cardData = findActivePlanAndCalculateTotal(
        groupYearlyPlanId,
        memCurrQuantity
      );
      setUpdateCardRange(cardData?.groupRange);
      setUpdateCardRate(cardData?.pricePerUnit);
      setUpdateCardCalculatedPrice(cardData?.totalAmount);
    }
  }, [plansData]);

  const getMonthlyGroupPrice = (val) => {
    switch (true) {
      case 3 <= val && val < 21:
        return val * 2.96;

      case 21 <= val && val < 51:
        return val * 2.76;

      case 51 <= val && val < 91:
        return val * 2.37;

      case val > 90:
        return val * 1.9;

      default:
        3;
    }
  };

  const getYearlyGroupPrice = (val) => {
    switch (true) {
      case 3 <= val && val < 21:
        return val * 22.46;

      case 21 <= val && val < 51:
        return val * 20.97;

      case 51 <= val && val < 91:
        return val * 17.97;

      case val > 90:
        return val * 14.86;

      default:
        3;
    }
  };

  const handleClose = () => setShow(false);
  const handleYearlyClose = () => setYearlyShow(false);

  const upgradeSubscription = async (subscriptionId, quantity, subType) => {
    const planId = getPlanIdByName[subType];

    if (
      (subType === "Group monthly" || subType === "Group Yearly") &&
      quantity < 3
    ) {
      toast.error("Quantity should be greater than or equal to 3.");
      setisLoader(false);
      return;
    }

    try {
      let priceDescription = await fetch(
        `${BASEURL}/subscription/calculateProratedAmount?subscriptionId=${subscriptionId}&quantity=${quantity}&planId=${planId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      let data = await priceDescription.json();
      setUpgradeShow(true);
      setPriceDetails(data);

      setisLoader(false);
    } catch (error) {
      toast.error(error);
      console.log("error", error);
    }
  };

  const updateOrUpgradeSubscriptionHandler = async (
    shippingAmount,
    isUpdate,
    isIndividual,
    subType,
    newSubType
  ) => {
    // const qty = isUpdate ? updatedQty : yearlyGroupNumber;
    setisLoader(true);
    setIsConfirmLoading(true);

    let qty =
      newSubType === "Group monthly" ? monthlyGroupNumber : yearlyGroupNumber;

    let planId;

    if (isUpdate) {
      planId = getPlanIdByName[subType];
      qty = updatedQty;
    } else {
      planId = newPlanId;
    }

    const bodyData = {
      UserEmail: useEmail,
      quantity: qty,
      shipping_amount: shippingAmount,
      planId: planId,
    };

    if (isIndividual) {
      delete bodyData?.shipping_amount;
      delete bodyData?.quantity;
    }

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyData),
      };

      const ApiRes = await fetch(
        `${BASEURL}/subscription/updateOrUpgradePlan`,
        requestOptions
      );

      const data = await ApiRes.json();

      if (data?.error?.statusCode === 400) {
        toast.error(data.message);
      }

      if (data.success) {
        const newData = await data.data.links.find(
          (link) => link.rel === "approve"
        );

        // Redirect to PayPal in the same tab
        window.location.href = newData.href;
        setisLoader(false);
      }
    } catch (error) {
      console.log(error);
      setisLoader(false);
      setIsConfirmLoading(false);
      toast.error(error);
    }
  };

  // Function to find the active plan by planId and get group range and price
  const findActivePlanAndCalculateTotal = (planId, quantity) => {
    const activePlan = plansData?.find(
      (plan) => plan.id === planId && plan.status === "ACTIVE"
    );

    if (activePlan) {
      const pricingScheme = activePlan.billing_cycles[0].pricing_scheme;
      let groupRange = "";
      let pricePerUnit = "";

      pricingScheme.tiers.forEach((tier) => {
        if (
          quantity >= parseInt(tier.starting_quantity) &&
          (!tier.ending_quantity || quantity <= parseInt(tier.ending_quantity))
        ) {
          groupRange = `${tier.starting_quantity}-${
            tier.ending_quantity || "Above"
          }`;
          pricePerUnit = tier.amount.value;
        }
      });

      if (groupRange && pricePerUnit) {
        const totalAmount = quantity * parseFloat(pricePerUnit);
        return {
          groupRange: groupRange,
          pricePerUnit: `$${pricePerUnit}/unit`,
          totalAmount: `$${totalAmount.toFixed(2)}`,
        };
      }
    }

    return null; // Return null if active plan not found or quantity not in any tier range
  };

  return (
    <>
      {isLoader && <Loader />}
      <div style={{ paddingTop: "6rem" }}>
        <Navbar MemberType={MemberTyp} />
        <div className="container">
          <h2 className="mt-1 mb-5 text mx-auto">Upgrade Subscription Plans</h2>
          <Row>
            {/* yearly card start */}
            {console.log("MEM TYPE : ", MemberTyp)}
            <Col md={12}>
              {MemberTyp === "monthly" && (
                <div className="card-main custm-border">
                  <div className="card-head">
                    <h4 className="head-card-text">Individual subscription</h4>
                  </div>
                  <div className="body-card formSection form-custm-group">
                    <div className="card-inner-p">
                      <Row>
                        <Col md={6} className="">
                          <div className="subscription-box mx-auto">
                            <div className="Plan-status">
                              <button className="plan">Upgrade plan</button>
                            </div>
                            <h2 className="subscription-title">
                              Subscription Options
                            </h2>
                            <div className="sub-title">
                              Readmax Subscription Package
                            </div>
                            <span className="yearly-price">
                              <span className="t-header_currency">$</span>
                              <span className="t-header_currency">
                                {yPrice} USD
                              </span>
                              <span className="t-price_suffix">/Yearly</span>
                            </span>
                            <button
                              onClick={() => {
                                setisLoader(true);
                                setUpgradeShow(true);
                                setisIndividual(true);
                                setNewSubType("yearly");
                                upgradeSubscription(
                                  subscriptionId,
                                  1,
                                  "yearly"
                                );

                                setNewPlanId(
                                  process.env.REACT_APP_PRODUCT_YEARLY_PLAN_ID
                                );
                              }}
                              className="btn btn-primary upgrade-subscription-btn"
                            >
                              Upgrade Subscription
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              )}
            </Col>
            {/* yearly card end */}
            <Col md={12}>
              {(MemberTyp === "Group monthly" ||
                MemberTyp === "Group Yearly" ||
                MemberTyp === "monthly" ||
                MemberTyp === "yearly") && (
                <div className="card-main custm-border">
                  <div className="card-head">
                    <h4 className="head-card-text">Group Subscription</h4>
                  </div>
                  <div className="body-card formSection form-custm-group">
                    <div className="card-inner-p">
                      <Row className="">
                        {/* starting of the update card */}
                        {(MemberTyp === "Group Yearly" ||
                          MemberTyp === "Group monthly") && (
                          <Col md={6} className="">
                            <div className="subscription-box active mx-auto">
                              <div className="Plan-status">
                                <button className="plan">Active plan</button>
                              </div>
                              <h2 className="subscription-title">
                                Group Subscription Options
                              </h2>
                              <div className="sub-title">
                                Readmax Group Subscription Package
                              </div>
                              <br />
                              <div
                                className="view"
                                style={{ cursor: "pointer" }}
                                onClick={showInfoRange}
                              >
                                View Subscriptions Plans <InfoIcon />
                              </div>
                              <br />
                              <p>
                                Group range and Price: {updateCardRange}{" "}
                                {updateCardRate}
                              </p>
                              <p>
                                <strong>Note:-</strong>Group range and price
                                will show above according to the quantity we
                                selected
                              </p>
                              <div>
                                <input
                                  type="number"
                                  id="group_range"
                                  defaultValue={currPlanQuantity}
                                  name="group_range"
                                  min="3"
                                  onChange={(e) => {
                                    const { value } = e.target;
                                    if (value.includes(".")) {
                                      e.target.value = value.replace(".", "");
                                    }
                                    setupdatedQty(e.target.value);

                                    setquantityForUpdateQunatityCard(
                                      e.target.value
                                    );
                                    const groupPlanId =
                                      MemberTyp === "Group monthly"
                                        ? groupMonthlyPlanId
                                        : groupYearlyPlanId;
                                    const cardData =
                                      findActivePlanAndCalculateTotal(
                                        groupPlanId,
                                        e.target.value
                                      );
                                    setUpdateCardRange(cardData?.groupRange);
                                    setUpdateCardRate(cardData?.pricePerUnit);
                                    setUpdateCardCalculatedPrice(
                                      cardData?.totalAmount
                                    );
                                  }}
                                  style={{
                                    border: "1px solid gray",
                                    outline: "none",
                                    borderRadius: "5px",
                                    padding: "2px",
                                    textAlign: "center",
                                  }}
                                />
                                {updatedQty < 3 && (
                                  <small className="d-block invalid-feedback">
                                    Quntity Can't be less than 3
                                  </small>
                                )}
                              </div>
                              <span className="yearly-price">
                                <span className="t-header_currency">
                                  {updateCardCalculatedPrice} USD
                                </span>
                                <span className="t-price_suffix">
                                  /
                                  {MemberTyp === "Group monthly"
                                    ? "Monthly"
                                    : "Yearly"}
                                </span>
                              </span>
                              <button
                                onClick={(e) => {
                                  setupdatedQty(quantityForUpdateQunatityCard);
                                  setUpdateVal(true);
                                  setisLoader(true);
                                  setNewSubType(MemberTyp);
                                  setIsUpdate(true);
                                  upgradeSubscription(
                                    subscriptionId,
                                    quantityForUpdateQunatityCard,
                                    MemberTyp
                                  );
                                }}
                                // onClick={() => {
                                //   updateOrUpgradeSubscriptionHandler();
                                // }}
                                className="btn btn-primary upgrade-subscription-btn"
                              >
                                Update Quantity
                              </button>
                            </div>
                          </Col>
                        )}
                        {/* ending of the update card */}
                        {MemberTyp === "Group monthly" ||
                        MemberTyp === "Group Yearly" ? null : (
                          <Col md={6} className="">
                            <div className="subscription-box mx-auto">
                              <div className="Plan-status">
                                <button className="plan">Upgrade plan</button>
                              </div>
                              <h2 className="subscription-title">
                                Group Subscription Options
                              </h2>
                              <div className="sub-title">
                                Readmax Group Subscription Package
                              </div>
                              <br />
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={showInfoRange}
                              >
                                View Subscriptions Plans <InfoIcon />
                              </div>
                              <br />
                              <p className="updateqtY">
                                Group range and Price: {groupMonthlyRange}{" "}
                                {groupMonthlyRate}
                              </p>
                              <p>
                                <strong>Note:-</strong>Group range and price
                                will show above according to the quantity we
                                selected
                              </p>
                              <div>
                                <input
                                  type="number"
                                  id="group_range"
                                  defaultValue={3}
                                  name="group_range"
                                  min="3"
                                  max="100"
                                  onChangeCapture={(e) => {
                                    const { value } = e.target;
                                    if (value.includes(".")) {
                                      e.target.value = value.replace(".", "");
                                      // setupdatedQty(e.target.value);
                                    }
                                    setMonthlyGroupNumber(e.target.value);

                                    setupdatedQty(e.target.value);
                                    const cardData =
                                      findActivePlanAndCalculateTotal(
                                        groupMonthlyPlanId,
                                        e.target.value
                                      );

                                    setGroupMonthlyRange(cardData?.groupRange);
                                    setGroupMonthlyRate(cardData?.pricePerUnit);
                                    setGroupMonthlyCalculatedPrice(
                                      cardData?.totalAmount
                                    );
                                  }}
                                  ref={quantityRef}
                                  style={{
                                    border: "1px solid gray",
                                    outline: "none",
                                    borderRadius: "5px",
                                    padding: "2px",
                                    textAlign: "center",
                                  }}
                                />
                                {monthlyGroupNumber < 3 && (
                                  <small className="d-block invalid-feedback">
                                    Quntity Can't be less than 3
                                  </small>
                                )}
                              </div>
                              <span className="yearly-price">
                                <span className="t-header_currency">
                                  {groupMonthlyCalculatedPrice} USD
                                </span>
                                <span className="t-price_suffix">/Monthly</span>
                              </span>
                              <button
                                onClick={() => {
                                  setisLoader(true);
                                  setisIndividual(false);
                                  setupdatedQty(monthlyGroupNumber);
                                  setNewSubType("Group monthly");
                                  setNewPlanId(groupMonthlyPlanId);
                                  setIsUpdate(false);
                                  setPlanType("Group monthly");
                                  upgradeSubscription(
                                    subscriptionId,
                                    monthlyGroupNumber,
                                    "Group monthly"
                                  );
                                }}
                                className="btn btn-primary upgrade-subscription-btn"
                              >
                                Upgrade Subscription
                              </button>
                            </div>
                          </Col>
                        )}
                        {MemberTyp === "Group Yearly" ? null : (
                          <Col md={6} className="">
                            <div className="subscription-box mx-auto">
                              <div className="Plan-status">
                                <button className="plan">Upgrade plan</button>
                              </div>
                              <h2 className="subscription-title">
                                Group Subscription Options
                              </h2>
                              <div className="sub-title">
                                Readmax Group Subscription Package
                              </div>
                              <br />
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={showInfoYearlyRange}
                              >
                                View Subscriptions Plans <InfoIcon />
                              </div>
                              <br />

                              <p className="updateqtY">
                                Group range and Price : {groupYearlyRange}{" "}
                                {groupYearlyRate}
                              </p>
                              <p>
                                <strong>Note:-</strong>Group range and price
                                will show above according to the quantity we
                                selected
                              </p>
                              <div>
                                <input
                                  type="number"
                                  id="group_range"
                                  defaultValue={3}
                                  name="group_range"
                                  // value={Math.max(0)}
                                  min="3"
                                  max="100"
                                  ref={yearlyQuantityRef}
                                  onChange={(e) => {
                                    const { value } = e.target;
                                    if (value.includes(".")) {
                                      e.target.value = value.replace(".", "");
                                      // setupdatedQty(e.target.value);
                                    }

                                    setYearlyGroupNumber(e.target.value);
                                    setupdatedQty(e.target.value);

                                    const cardData =
                                      findActivePlanAndCalculateTotal(
                                        groupYearlyPlanId,
                                        e.target.value
                                      );
                                    setGroupYearlyRange(cardData?.groupRange);
                                    setGroupYearlyRate(cardData?.pricePerUnit);
                                    setGroupYearlyCalculatedPrice(
                                      cardData?.totalAmount
                                    );
                                  }}
                                  style={{
                                    border: "1px solid gray",
                                    outline: "none",
                                    borderRadius: "5px",
                                    padding: "2px",
                                    textAlign: "center",
                                  }}
                                />
                                {yearlyGroupNumber < 3 && (
                                  <small className="d-block invalid-feedback">
                                    Quntity Can't be less than 3
                                  </small>
                                )}
                              </div>
                              <span className="yearly-price">
                                <span className="t-header_currency">
                                  {groupYearlyCalculatedPrice} USD
                                </span>
                                <span className="t-price_suffix">/Yearly</span>
                              </span>
                              <button
                                onClick={() => {
                                  setisLoader(true);
                                  setisIndividual(false);
                                  setupdatedQty(yearlyGroupNumber);
                                  setNewSubType("Group Yearly");
                                  setNewPlanId(groupYearlyPlanId);
                                  setIsUpdate(false);
                                  setPlanType("Group Yearly");
                                  upgradeSubscription(
                                    subscriptionId,
                                    yearlyGroupNumber,
                                    "Group Yearly"
                                  );
                                }}
                                className="btn btn-primary upgrade-subscription-btn"
                              >
                                Upgrade Subscription
                              </button>
                            </div>
                          </Col>
                        )}
                      </Row>
                    </div>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </div>
        <Footer />
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title
              style={{
                fontFamily: "sans-serif",
                fontSize: "18px",
                fontWeight: "600",
              }}
            >
              Price and Range
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container fluid>
              <div>
                <Table bordered className="price-info-table">
                  <thead
                    style={{ background: "rgb(5 132 206)" }}
                    className="price-info"
                  >
                    <tr>
                      <td>#</td>
                      <td>Group Range</td>
                      <td>Price</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>1-20</td>
                      <td>$2.96</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>21-50</td>
                      <td>$2.76</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>51-90</td>
                      <td>$2.37</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>91-Above</td>
                      <td>$1.90</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Container>
          </Modal.Body>
          {/* <Modal.Footer style={{ height: "3.25rem" }}>
        </Modal.Footer> */}
        </Modal>
        <Modal show={yearlyShow} onHide={handleYearlyClose} centered>
          <Modal.Header closeButton>
            <Modal.Title
              style={{
                fontFamily: "sans-serif",
                fontSize: "18px",
                fontWeight: "600",
              }}
            >
              Price and Range
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <div>
                <Table bordered className="price-info-table">
                  <thead
                    style={{ background: "rgb(5 132 206)" }}
                    className="price-info"
                  >
                    <tr>
                      <td>#</td>
                      <td>Group Range</td>
                      <td>Price</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>1-20</td>
                      <td>$22.46</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>21-50</td>
                      <td>$20.97</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>51-90</td>
                      <td>$17.97</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>91-Above</td>
                      <td>$14.86</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Container>
          </Modal.Body>
        </Modal>
        <UpgradePlanModal
          priceDetails={priceDetails}
          upgradeShow={upgradeShow}
          handleClose={() => setUpgradeShow(false)}
          updateVal={updateVal}
          updateOrUpgradeSubscriptionHandler={
            updateOrUpgradeSubscriptionHandler
          }
          isUpdate={isUpdate}
          yearlyGroupNumber={yearlyGroupNumber}
          newPlanId={newPlanId}
          planType={planType}
          shippingAmount={shippingAmount}
          isIndividual={isIndividual}
          MemberTyp={MemberTyp}
          newSubType={newSubType}
          updatedQty={updatedQty}
          isConfirmLoading={isConfirmLoading}
        />
      </div>
    </>
  );
};

export default UpgradeSubscription;
